
@mixin themify-color($themes: $themes, $key: 'color') {
    @each $theme, $map in $themes {
        .#{$theme} & {
            color: map-get($map, $key);
        }
    }
}
@mixin themify-background($themes: $themes) {
    @each $theme, $map in $themes {
        .#{$theme} & {
            background-color: map-get($map, color);
        }
    }
}
@mixin themify-inverse($themes: $themes) {
    @each $theme, $map in $themes {
        .#{$theme} & {
            background-color: map-get($map, inverse-background);
            color: map-get($map, inverse-text-color);

            a {
                color: map-get($map, inverse-link-color);
                &:hover {
                    color: map-get($map, inverse-link-color-hover);
                }
            }
        }
    }
}
