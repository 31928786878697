@charset "utf-8";

// third party mixins and functions
@import "vendor/bourbon/bourbon";

// base
@import "variables";            // color + font variables
@import "fonts";                // fonts
@import "functions/functions";  // utilities
@import "mixins/mixins";        // utilities

// third party
@import "vendor/neat/neat";
@import "vendor/font-awesome/font-awesome";

// bootstrap
@import "vendor/bootstrap/bootstrap";
/*
@import "vendor/bootstrap/bootstrap/variables";
@import "vendor/bootstrap/bootstrap/mixins";

@import "vendor/bootstrap/bootstrap/normalize";
@import "vendor/bootstrap/bootstrap/print";

@import "vendor/bootstrap/bootstrap/scaffolding";
@import "vendor/bootstrap/bootstrap/grid";
@import "vendor/bootstrap/bootstrap/tables";
@import "vendor/bootstrap/bootstrap/forms";
@import "vendor/bootstrap/bootstrap/buttons";

@import "vendor/bootstrap/bootstrap/utilities";
*/

// components
@import "base";            // base
@import "intro";            // intro - landingpage
@import "navs";            // navigations
@import "panels";          // fullwidth sections
@import "typo";            // typography
