h1 {
    font-size: $fs-1;
    font-weight: 200;
}
h2 {
    font-size: $fs-2;
    font-weight: 400;
}
h3 {
    font-size: $fs-3;
    font-weight: 400;
}
h4 {
    font-size: $fs-4;
    font-weight: 600;
}
h5 {
    font-size: $fs-5;
    font-weight: 600;
}
h6 {
    font-size: $fs-6;
    font-weight: 600;
}

p {
    margin: 0 0 $spacing-half;
}

p code {
	background: $color-a-dark;
	padding: 1px 5px;
	border-radius: 3px;
    color: $color-a-lighter;
    line-height: 2.0;
}
code, kbd, pre, samp {
    font-family: Menlo, Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: rem(12);
}

blockquote {
    margin: $spacing-half 0 $spacing;
	padding: $spacing-half $spacing;
	background: $color-primary;
	border-radius: 3px;
	color: #fff;
	p:last-child {
		margin: 0;
	}
	a {
		color: #fff;
	}
}
