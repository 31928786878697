
//== Image Path
$img-path:          "../../../assets/img";

//== Asset Path
$asset-path:          "/files";

//== Font Path
$font-path:          "../../../assets/fonts";
$fa-font-path:      $font-path;


//== Themes
$themes: (
        sch: (
            // Schulen Wermsdorf - Landingpage
                color: #004d48,
                nav-link-color-active: #004d48
        ),
        gsw: (
            // Grundschule Wermsdorf
                color: #00a3b8,
                nav-link-color-active: #82eefc
        ),
        osw: (
            // Oberschule Wermsdorf
                color: #7cb142,
                nav-link-color-active: #7cb142
        ),
        gsc: (
            // Grundschule Calbitz
                color: #ea7930,
                nav-link-color-active: #ea7930
        ),
        sfw: (
            // Schulförderverein Wermsdorf
                color: #115951,
                nav-link-color-active: #00a3b8
        )
);
//== Colors
$color-navbar-background: #056061;


$color-a-lighter:   #f3f6fb;
$color-a-light:     #dfe3e9;
$color-a:           #9ca5b2;
$color-a-dark:      #343f50;
$color-a-darker:    #2a3542;

$color-b-light:     #bdd9f7;
$color-b:           #5b98d7;

$color-c-lighter:   #e2f6ee;
$color-c:           #4eb794;

$color-primary:     $color-b;
$color-secondary:   $color-c;

$font: 'Signika Negative', sans-serif;

$fs-1: rem(50);
$fs-2: rem(30);
$fs-3: rem(24);
$fs-4: rem(21);
$fs-5: rem(18);
$fs-6: rem(16);
$fs-7: rem(15);
$fs-base: $fs-7;
$lh-base: 1.6;

$spacing: $lh-base * rem(16);
$spacing-half: $spacing / 2;

//== Neat Variables
$visual-grid: false !global;
$visual-grid-color: #eee !global;
$visual-grid-index: back !global;
$visual-grid-opacity: .2 !global;
$max-width: em(1170, 15) !global;

//== Bootstrap Variables
$brand-primary: $color-primary;

$font-family-base: $font;
$font-size-base: 15px;
$line-height-base: $lh-base;

$grid-float-breakpoint: 992px; // > $screen-md-min
