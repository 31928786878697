.sch article {
    padding-top: 0;
}
.intro-header {
    border-top: 8px solid map-deep-get($themes, 'sch', 'color');
}
.intro-nav {
    padding: floor($grid-gutter-width / 2) $grid-gutter-width;
    font-size: $fs-5;
    text-align: right;
    a {
        color: map-deep-get($themes, 'sch', 'color');
    }
}
.intro-panel {
    color: map-deep-get($themes, 'sch', 'color');
    font-size: $fs-5;
    text-align: center;

    h1, h2 {
        font-size: $fs-3;
    }
    h2 {
        font-weight: 600;
    }

    .tiles {
        margin-bottom: rem(24);
        padding-left: rem(45);
        padding-right: rem(45);
        padding-bottom: rem(24);
        @include gradient-horizontal-three-colors(#003a38, #005a52, 50%, #003a38);
        color: #fff;

        .col-md-4 {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .sfw {
        font-size: $fs-3;
    }
}
.intro-btn {
    @include themify-background();
    display: inline-block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    color: #fff;
    font-size: $fs-4;
    line-height: 64px;
    text-align: center;

    .tiles & {
        margin-top: -40px;
    }
}
