html {
    font-size: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    -webkit-font-smoothing: none;
}

body {
    background-image: url("#{$img-path}/pattern.png");
    font-size: $fs-base;
}

main {
}
.main-wrapper {
}


.global-header {
    @include themify-background();
    padding-top: 8px;
}
.header-slider {
    padding-bottom: 8px;
}
.header-btn {
    @include themify-background();
    position: absolute;
    right: 120px;
    top: 0;
    z-index: 200;
    padding-left: 20px;
    padding-right: 44px;
    border-radius: 16px;
    color: #fff;
    font-size: $fs-5;
    line-height: 40px;

    &:hover {
        color: #fff;
    }

    .global-header .container + .navbar-top & {
        top: -24px;
    }
}

main {
    padding-top: rem(60);
    background-color: #fff;
    .sch & {
        background-color: transparent;
    }
    > .container {
        padding-left: ($grid-gutter-width + ($grid-gutter-width / 2));
        padding-right: ($grid-gutter-width + ($grid-gutter-width / 2));
    }
    .row {
        margin-left: -($grid-gutter-width);
        margin-right: -($grid-gutter-width);
    }
    .col-md-4,
    .col-md-8 {
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
    }
}

.owl-nav {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: -16px;
    z-index: 100;


    .owl-prev,
    .owl-next {
        @include themify-background();
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: #fff;
        font-size: $fs-4;
        line-height: 40px;
        text-align: center;
    }
    .owl-prev {
        float: left;
        text-shadow: -4px 0 2px rgba(0, 0, 0, 0.2);
    }
    .owl-next{
        float: right;
        text-shadow: 4px 0 2px rgba(0, 0, 0, 0.2);
    }
}
.owl-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    text-align: center;
}
.owl-dot {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 7px;
    border: 2px solid #fff;
    border-radius: 50%;
    &.active {
        background-color: #fff;
    }
}

.blocks,
.block-teasers,
.teasers,
.video-block,
.download-block,
.table-block {
    margin-top: ($grid-gutter-width * 2);
    margin-bottom: ($grid-gutter-width * 2);

    &:first-child {
        margin-top: 0;
    }
}
.block,
.teaser {
    font-weight: 300;
    h1, h2 {
        @include themify-color($themes);
        font-size: $fs-3;
    }
    strong, b {
        @include themify-color($themes);
    }
}
.block {
    h1, h2 {
        font-weight: 700;
    }
    a {
        @include themify-color($themes);
    }
}
.teaser {
    padding: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
    h1, h2 {
        margin-top: 0;
        font-weight: 600;
    }
    p {
        margin-bottom: 0;
    }
    a {
        position: relative;
        display: block;
        color: $text-color;

        &:before {
            @include themify-background($themes);
            content: '';
            position: absolute;
            right: -10px;
            bottom: -10px;
            z-index: 0;
            display: block;
            width: 55px;
            height: 87px;
            background-image: url("#{$img-path}/teaser.png");
        }
        &:after {
            content: '\f061';
            color: #fff;
            font: normal normal normal 14px / 1 FontAwesome;
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
    }
    a:hover {
        text-decoration: none;
    }
    .teaser-body {
        position: relative;
        z-index: 2;
    }
}

.block-teasers .teaser + .teaser {
    margin-top: 20px;
}


.runner {
    fill: #fff;
}
.runner-shadow {
    fill: #999;

    .osw & {
        fill: #5e8935;
    }
    .gsw & {
        fill: #186877;
    }
    .gsc & {
        fill: #b2632c;
    }
    .sfw & {
        fill: #0a2926;
    }
}
