.panel {
    .case {
        @include outer-container();
    }
    .content {
        @include span-columns(10);
        @include shift(1);
    }

    &.hero {
        text-align: center;

        h1 {
            color: $color-primary;
            font-weight: 600;
        }
        p {
            color: $color-a;
            font-size: $fs-4;
        }
    }
}
