.navbar-top {
    position: relative;
    background-color: $color-navbar-background;

    .navbar-case {
        position: relative;
        @include gradient-horizontal-three-colors(#003a38, #004944, 50%, #003a38);

        &:after {
            content: '';
            position: absolute;
            left: -6px;
            right: -6px;
            bottom: -9px;
            z-index: 1;
            display: block;
            height: 14px;
            background-image: url("#{$img-path}/nav-line.png");
            border-radius: 7px;
            box-shadow: 0 10px 10px -5px rgba(0,0,0,0.5);
        }
        .navbar-collapse {
            @media (min-width: $grid-float-breakpoint) {
                padding-right: 7px;
                padding-left: 7px;
            }
        }
    }
}
.navbar-header,
.navbar-brand {
    @media (min-width: $grid-float-breakpoint) {
        float: none;
        text-align: center;
    }
}
.navbar-header {
    padding: 30px 0 8px 0;
}
.navbar-brand {
    color: #fff;
    font-size: $fs-3;
    font-weight: 600;
    &:hover {
        color: #fff;

    }
}
.nav-main-list {
    @include reset-list;
    > li {
        display: block;
        margin: 0 ($grid-gutter-width / 2);
        padding: 0 3px;
        > a {
            display: block;
            color: #fff;
            font-size: $fs-5;
            &.active {
                font-weight: 700;
            }
            &:hover,
            &.active {
                @include themify-color($themes, 'nav-link-color-active');
                text-decoration: none;
            }
        }
        > ul {
            a {
                color: #fff;
                &:hover,
                &.active {
                    @include themify-color($themes, 'nav-link-color-active');
                    text-decoration: none;
                }
            }
        }
    }
    @media (min-width: $grid-float-breakpoint) {
        display: table;
        width: 100%;

        > li {
            position: relative;
            display: table-cell;
            text-align: center;
            > a {
                @include gradient-vertical(rgba($color-navbar-background,0), $color-navbar-background);
                &:after {
                    position: relative;
                    z-index: 2;
                    content: '';
                    display: block;
                    width: 52px;
                    height: 18px;
                    margin: 0 auto -1px auto;
                }
                &.active {
                    &:after {
                        background-image: url("#{$img-path}/nav-active.png");
                    }
                }
            }
            > ul {
                display: none;
                position: absolute;
                top: 100%;
                left: 3px;
                z-index: 100;
                margin-top: -5px;
                padding-right: rem(20);
                padding-top: rem(10);
                padding-bottom: rem(10);
                background-color: $color-navbar-background;
                color: #fff;
                text-align: left;
                list-style: disc;
                > li {
                   white-space: nowrap;
                }
            }
            &:hover {
                > ul {
                    display: block;
                }
            }
        }
    }
    @media (max-width: $grid-float-breakpoint-max) {
        padding: 10px 0;
        > li {
            > ul {
                @include reset-list;
                margin-left: 20px;
            }
        }
    }
}

.nav-sidebar-list {
    &, ul {
        @include reset-list();
    }
    > li {
        font-size: $fs-4;
        > ul {
            margin-top: $spacing;
            border-top: 1px solid $color-a-light;
            font-size: $fs-base;
            > li {
                border-bottom: 1px solid $color-a-light;
                > a {
                    display: block;
                    padding: rem(8) rem(12);
                    color: $color-b;
                    font-weight: 600;
                    &:hover,
                    &.active {
                        color: $color-a-dark;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}


.navbar-toggle {
    border-color: #fff;
    &:hover,
    &:focus {
        background-color: rgba(#fff, .5);
    }
    .icon-bar {
      background-color: #fff;
    }

}
